/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/HomePage.vue';
import About from './components/AboutPage.vue';
import ihpPage from './components/IhpPage.vue';
import Shop from './components/ShopPage.vue';
import Blog from './components/BlogPage.vue';
import Contact from './components/ContactPage.vue';
import BlogPost from './components/BlogPost.vue';
import TermsOfService from './components/TermsOfService.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import FullDisclaimer from './components/FullDisclaimer.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/ihp', component: ihpPage },
  { path: '/shop', component: Shop },
  { path: '/blog', component: Blog },
  { path: '/contact', component: Contact },
  { path: '/blog/:id', component: BlogPost },
  { path: '/terms-of-service', component: TermsOfService },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/full-disclaimer', component: FullDisclaimer }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;