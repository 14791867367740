<template>
    <div id="about-page" class="about-page">
        <div class="container">
          <nav class="nav-secondary">
                <div class="wrap">
                    <ul class="genesis-nav-menu">
                    <li class="menu-item">
                        <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                    </li>
                    <!-- <li class="menu-item">
                        <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                    </li> -->
                    <li class="menu-item">
                        <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                    </li>
                    </ul>
                </div>
            </nav>
        <h1 class="about-title">About Me</h1>
        <div class="content">
          <div class="image-container">
            <img src="../assets/profile.jpg" alt="Marianna Ionashku" class="profile-image">
          </div>
          <div class="text-container">
            <p>Hi, I'm Marianna, a Level 1 & 2 Integrative Health Practitioner (IHP) with a passion for helping others achieve optimal health and well-being. I've received training in Diet, Exercise, Stress, Emotional Health, Supplementation and Success Mindset and how to achieve balance in all of those fields with the help of holistic health practices using principles from Naturopathy, Ayurveda, Orthomolecular Medicine, Functional Medicine, TCM etc. I have also been trained in lab testing such as some blood chemistry and functional labs like: saliva hormone/adrenal testing, stool and organic acids testing, hair tissue mineral analysis and more.</p>
            <p>My journey began when I decided to dive deeper into my body's symptoms, including PMS, painful periods, occasional acne, and digestive discomforts. Growing up in a home where healthy organic foods, vitamins, and herbs were a part of everyday life, I always had an interest in holistic health. So naturally, I was going to go the natural route.</p>
          </div>

        </div>
        <div class="text-container1">
              <p>Determined to find solutions, I sought the guidance of naturopaths and immersed myself in courses to learn more about hormones, digestion, parasites, and other crucial aspects of health. As I pieced together the puzzle and implemented what I learned, I began to see drastic improvements in my body. My PMS disappeared, my cramps diminished, and digestive discomfort was no longer an issue.</p>
              <p>Inspired by my own transformation and with the encouragement of my friends and family, I recognized that my deep-seated passion lies in helping others achieve similar results. I continued my education and became a certified Integrative Health Practitioner.</p>
            </div>
            <div class="text-container2">
              <p>Now, I dedicate my time and energy to empowering individuals to take charge of their health and experience the life-changing benefits of natural healing. Through personalized guidance, functional lab testing, and a whole body approach, I help my clients restore balance, optimize their well-being, and unlock their body's innate ability to heal.</p>
            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'AboutPage',
  }
  </script>
  
  <style scoped>
  .about-page {
    padding: 50px;
    background-color: #e3e4d1;
    background-image: linear-gradient(rgba(227, 228, 209), rgba(227, 228, 209, 0.85)), url('../assets/noise.jpg');
    min-height: 100vh;
  }

  .nav-secondary .wrap {
    background-color: #e3e4d1 !important;
  }
  
  .container {
    max-width: 960px;
    margin: 0px auto;
    padding: 0 20px;
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  

  .content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.image-container {
  width: 60%;
  margin: 60px auto 0px;
}

.profile-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.text-container {
  width: 100%;
  margin: 60px auto 0px;
}
  .text-columns {
    flex: 2;
    display: flex;
    gap: 40px;
  }
  
  
  .text-container p:first-child,
  .text-container2 p:first-child {
    margin-top: 0;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    font-family: 'Poppins Regular';
    color: #1f1f1f;
  }

  .text-container1 {
    margin-top: 20px;
  }
  .about-title {
    font-size: 22px;
    line-height: 1.6;
    text-transform: uppercase;
    margin-bottom: 20px;
    LETTER-SPACING: 3px;
    font-family: 'Poppins Regular';
    color: #362d2d;
  }
  
  @media (min-width: 768px) {
  .content {
    flex-direction: row;
  }

  .image-container {
    flex: 1;
  }
  

  .text-container {
    flex: 2;
  }
}
  </style>