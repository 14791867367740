<template>
    <div id="ihp-page" class="ihp-page">
        <div class="container">
            <nav class="nav-secondary">
                <div class="wrap">
                    <ul class="genesis-nav-menu">
                    <li class="menu-item">
                        <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/ihp" exact-active-class="active"><span>ihp</span></router-link>
                    </li>
                    <!-- <li class="menu-item">
                        <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                    </li> -->
                    <li class="menu-item">
                        <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                    </li>
                    </ul>
                </div>
            </nav>
        <h1 class="ihp-title">Integrative Health</h1>

        <div class="content">

            <div class="text-columns">
            <div class="ihp-text-container">
                <p>Integrative Health draws from different forms of medicine including Ayurvedic, Bio-regulatory, Herbalism, Traditional Naturopathy,
                    Orthomolecular and Functional Medicine as well as the
                    Emotional/Spritual Influence on health. This gives it a rich array of tools/resources to pull from when it comes to healing the body.
                    Since the body is not just a sum of different parts, but rather a system that is all interconnected where one imbalance can impact another -- as an Integrative Health Practitioner, I look to work on full body health rather than symptom management. 
                </p>
            </div>
            <h1 class="ihp-subtitle">Level One</h1>
            <div class="ihp-text-container">
                <p>
                    A Level 1 Integrative Health Practitioner focuses on foundational health concepts, including nutrition, lifestyle modifications, and supplementation. They are trained to assess clients' health concerns, provide personalized recommendations, and support them in making sustainable changes to improve their overall well-being. 
                </p>
            </div>
            <h1 class="ihp-subtitle">Level Two</h1>
            <div class="ihp-text-container">
                <p>
                    As a Level 2 Integrative Health Practitioner, I have knowledge in functional lab testing, allowing me to dive deeper into identifying imbalances and underlying causes of health issues. This level of training enables me to interpret lab results, develop targeted plans, and provide a more comprehensive approach to supporting my clients' health goals.

                </p>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
  

<script>

</script>

<style scoped>

.ihp-page {
    background-color: #8b8d7b;
    min-height: 100vh;
    padding: 50px;
    background-image: linear-gradient(rgba(139, 141, 123), rgba(139, 141, 123, 0.85)), url('../assets/noise.jpg');
}

.ihp-text-container {
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins Regular' !important;
    line-height: 1.6;
    margin-bottom: 20px;
    /* LETTER-SPACING: 3px; */
    font-family: sans-serif;
    color: #323232;
    max-width: 60%;
    margin: 50px auto;
}

.ihp-title {
    color: #323232;
    margin: 80px auto;
    text-align: center;
    font-size: 26px;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.ihp-subtitle {
    color: #323232;
    margin: 80px auto 20px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 4px;
    text-transform: uppercase;
}
</style>