<template>
    <div id="contact-page" class="contact-page">
      <div class="container">
            <nav class="nav-secondary">
                <div class="wrap">
                    <ul class="genesis-nav-menu">
                    <li class="menu-item">
                        <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                    </li>
                    <!-- <li class="menu-item">
                        <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                    </li> -->
                    <li class="menu-item">
                        <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                    </li>
                    </ul>
                </div>
            </nav>
        <div class="form-container">
          <h1 class="contact-title">Contact Me</h1>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" v-model="name" required>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" v-model="email" required>
            </div>
            <div class="form-group">
              <label for="message">Message</label>
              <textarea id="message" v-model="message" required></textarea>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import emailjs from '@emailjs/browser';

  export default {
    data() {
      return {
        name: '',
        email: '',
        message: '',
      };
    },
    methods: {
        async submitForm() {
            try {
                const response = await emailjs.send('service_x52oen8', 'template_c01f7p7', {
                from_name: this.name,
                from_email: this.email,
                message: this.message,
                });

                if (response.status === 200) {
                alert('Message sent successfully!');
                this.resetForm();
                } else {
                console.error('Error:', response);
                alert('Failed to send the message. Please try again.');
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred. Please try again.');
            }
        },
      resetForm() {
        this.name = '';
        this.email = '';
        this.message = '';
      },
    },
    mounted() {
        emailjs.init('qum42frkT9QjS-Gs8');
    },
  };
  </script>
  
  <style scoped>
  .contact-page {
    background-color: #8b8d7b;
    min-height: 100vh;
    padding: 50px;
    background-image: linear-gradient(rgba(139, 141, 123), rgba(139, 141, 123, 0.85)), url('../assets/noise.jpg');
  }
  
  .form-container {
    max-width: 500px;
    margin: 10% auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .contact-title {
    color: #474e3d;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Poppins Regular';
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #474e3d;
    margin-left: 10px;
    font-family: 'Poppins Regular';
    font-weight: bold;
  }
  
  input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  textarea {
    width: 90%;
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    font-size: 16px;
  }
  
  button {
    display: block;
    padding: 10px;
    width: 100px;
    margin: 0 auto;
    background-color: #474e3d;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #3a3e32;
  }
  </style>