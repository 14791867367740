<template>
    <div id="blog-page" class="blog-page">
        <div class="container">
            <nav class="nav-secondary">
                <div class="wrap">
                    <ul class="genesis-nav-menu">
                    <li class="menu-item">
                        <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                    </li>
                    <!-- <li class="menu-item">
                        <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                    </li> -->
                    <li class="menu-item">
                        <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                    </li>
                    </ul>
                </div>
            </nav>
            <h1 class="blog-title">Coming Soon</h1>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>

.blog-page {
    background-color: #8b8d7b;
    min-height: 100vh;
    padding: 50px;
    background-image: linear-gradient(rgba(139, 141, 123), rgba(139, 141, 123, 0.85)), url('../assets/noise.jpg');
}


.blog-title {
    color: #bbdbe1;
    margin: 80px auto;
    text-align: center;
    font-size: 26px;
    letter-spacing: 4px;
    text-transform: uppercase;
}
</style>