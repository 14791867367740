<template>
    <div class="terms-page">
        <nav class="nav-secondary">
            <div class="wrap">
                <ul class="genesis-nav-menu">
                <li class="menu-item">
                    <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                </li>
                <!-- <li class="menu-item">
                    <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                </li> -->
                <li class="menu-item">
                    <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                </li>
                </ul>
            </div>
        </nav>
        <div class="terms-of-service">

            <h1>Privacy Policy</h1>
            
            <h2>Introduction</h2>
            <p>At mariannaionashku.com, we value privacy and believe that your privacy is very important. This privacy policy applies to all personal information given and received. We define personal information as anything that would personally identify you: your name, your phone & email contact information, your physical and/or mailing address, your preferences, and other information that is not generally available to the public. This policy applies solely to mariannaionashku.com and no other companies or entities.</p>
            
            <h2>Information Collection and Use</h2>
            <p>mariannaionashku.com gathers personal information about you (name and email address) when you sign up for our newsletter. Additionally, your browser provides us with your IP address and cookie information. We NEVER rent or sell your private information. Your information is safe and secure. We use this information to personalize your experience, communicate with you, improve our services, and provide strictly anonymous reporting to other clients.</p>
            
            <h2>Children's Privacy</h2>
            <p>Children under 13 must have a parent or guardian approve registration with our newsletter or forum. We do not contact children under 13 for personal information, sales promotions, or marketing purposes.</p>
            
            <h2>Sharing of Personal Information</h2>
            <p>We do not rent, sell, give, or share any of your personal information unless it’s to provide a service or product YOU request or is regulated by confidential requirements or is required by law. Your information is never made available without your explicit consent.</p>
            
            <h2>Opt-Out and Unsubscribe</h2>
            <p>You are always free to terminate your relationship with mariannaionashku.com and request that your information be removed from our system. Simply click the link at the bottom of any one of the emails you receive to unsubscribe at any time.</p>
            
            <h2>Security</h2>
            <p>We provide complete physical, electronic, and procedural security steps that comply with all federal government regulations to protect your information.</p>
            
            <h2>Changes to Privacy Policy</h2>
            <p>From time to time, this policy may be modified. If you have any questions, please feel free to contact us.</p>
            
            <h2>Your Options and Rights under GDPR</h2>
            <ul>
                <li><strong>• Opting out:</strong> You may contact us at any time to opt-out or unsubscribe from direct marketing communications, outreach via phone or text, or our collection of sensitive personal data.</li>
                <li><strong>• Accessing your information:</strong> You have the right to request a copy of the information we hold about you.</li>
                <li><strong>• Correct, update, or request deletion:</strong> You may submit any desired changes to account information, update, or request deletion of any of your personal information.</li>
                <li><strong>• Issue a complaint:</strong> You have the right to complain to a data protection authority about the collection and use of your personal information.</li>
                <li><strong>• Concerns:</strong> Additional questions and concerns regarding the information in this policy can be directed to marianna.ionashku@gmail.com.</li>
            </ul>
            
            <h2>Cookies</h2>
            <p>mariannaionashku.com uses 1st party cookies to track the pages that users visit during their online session, for marketing and advertising purposes, to help improve user experiences, and/or to help understand how the website is being used. Third-party companies may also set their own cookies for traffic and conversion analysis and/or remarketing purposes.</p>
            
            <h2>Third-Party Websites</h2>
            <p>Participants may find advertising or other content on mariannaionashku.com that links to the websites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these websites and are not responsible for the practices employed by websites linked to or from mariannaionashku.com.</p>
            
            <h2>Changes to Privacy Policy</h2>
            <p>mariannaionashku.com has the discretion to update this Privacy Policy at any time. We encourage you to frequently check this page for any changes. Your continued use of mariannaionashku.com following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
            
            <h2>Acceptance of Terms</h2>
            <p>By using mariannaionashku.com, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use mariannaionashku.com. Your continued use of mariannaionashku.com following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
        </div>
    </div>  
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
  }
</script>


<style scoped>

.terms-page {
    background-color: #8c8d7b;
    width: 100vw;
}
  .terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    font-family: 'Poppins Regular';
  }

  h2 {
    font-size: 1.5rem;
    color: #474e3d;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  h3 {
    font-size: 1rem;
  }

  .terms-links {
    color: blue;
  }
</style>