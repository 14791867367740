<template>
    <div class="terms-page">
        <nav class="nav-secondary">
            <div class="wrap">
                <ul class="genesis-nav-menu">
                <li class="menu-item">
                    <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                </li>
                <!-- <li class="menu-item">
                    <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                </li> -->
                <li class="menu-item">
                    <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                </li>
                </ul>
            </div>
        </nav>
        <div class="terms-of-service">

            <h1>Terms of Service</h1>
                
                <h2>Introduction</h2>
                <p>Welcome to mariannaionashku.com! These terms and conditions outline the rules and regulations for the use of the Website, located at <a href="https://mariannaionashku.com/">https://mariannaionashku.com/</a>.</p>
                <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use mariannaionashku.com if you do not agree to take all of the terms and conditions stated on this page.</p>
                
                <h2>Cookies</h2>
                <p>We employ the use of cookies. By accessing mariannaionashku.com, you agreed to use cookies in agreement with Marianna Ionashku's Privacy Policy.</p>
                
                <h2>License</h2>
                <p>Unless otherwise stated, Marianna Ionashku and/or its licensors own the intellectual property rights for all material on mariannaionashku.com. All intellectual property rights are reserved. You may access this from mariannaionashku.com for your own personal use subject to restrictions set in these terms and conditions.</p>
                
                <h3>You must not:</h3>
                <ul>
                    <li>• Republish material from mariannaionashku.com</li>
                    <li>• Sell, rent or sub-license material from mariannaionashku.com</li>
                    <li>• Reproduce, duplicate or copy material from mariannaionashku.com</li>
                    <li>• Redistribute content from mariannaionashku.com</li>
                </ul>
                
                <h2>Hyperlinking to our Content</h2>
                <p>The following organizations may link to our Website without prior written approval:</p>
                <ul>
                    <li>• Government agencies;</li>
                    <li>• Search engines;</li>
                    <li>• News organizations;</li>
                    <li>• Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                    <li>• System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</li>
                </ul>
                
                <h2>Disclaimer</h2>
                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                <ul>
                    <li>• limit or exclude our or your liability for death or personal injury;</li>
                    <li>• limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>• limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>• exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>
                
                <h2>Privacy Policy</h2>
                <p>For our full Privacy Policy, please visit our <router-link class="terms-links" to="/privacy-policy"><span>Privacy Policy</span></router-link>.</p>
                
                <h2>Consent</h2>
                <p>By using our website, you hereby consent to our Terms of Use and agree to its Privacy Policy.</p>
                
                <h2>Update</h2>
                <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>
        </div>
    </div>  
</template>

<script>
  export default {
    name: 'TermsOfService',
  }
</script>

<style scoped>

.terms-page {
    background-color: #8c8d7b;
    width: 100vw;
}
  .terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    font-family: 'Poppins Regular';
  }

  h2 {
    font-size: 1.5rem;
    color: #474e3d;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  h3 {
    font-size: 1rem;
  }

  .terms-links {
    color: blue;
  }
</style>