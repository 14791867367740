<template>
  <div class="component">
    <div class="background-image">
      <video autoplay loop muted playsinline>
        <source src="../assets/grain.mov" type="video/mp4">
      </video>
      <div class="text-overlay">
        <h1 class="name">
          <span>MARIANNA</span>
          <br>
          <span>IONASHKU</span>
        </h1>
        <span class="subtitle">INTEGRATIVE HEALTH PRACTITIONER</span>
        <div class="button-container button-container-bottom">
          <router-link class="custom-button" to="/ihp"><span>INTEGRATIVE HEALTH</span></router-link>
          <a href="https://form.jotform.com/240876582768171" target="_blank" class="custom-button">WORK WITH ME</a>
        </div>
        
      </div>
    </div>
    <nav class="nav-secondary">
        <div class="wrap">
            <ul class="genesis-nav-menu">
            <li class="menu-item">
                <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
            </li>
            <li class="menu-item">
                <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
            </li>
            <li class="menu-item">
                <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
            </li>
            <!-- <li class="menu-item">
                <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
            </li> -->
            <li class="menu-item">
                <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
            </li>
            <li class="menu-item">
                <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
            </li>
            </ul>
        </div>
    </nav>
    <div class="section-1">
      <div class="text-container">
        <p class="text-effect">People have been healing their bodies for centuries before modern medicine even became a thing — yet so many are struggling with chronic health issues today.<br>
        <br>Where’s the disconnect?<br>
        <br>We just stopped listening to our bodies.<br>
        <br>The holistic/natural health movement has gained significant traction with people becoming dissatisfied with their experience of going to doctors and not getting answers, and  being recommended bandaid approaches.<br>
        <br>Our bodies were designed to thrive and heal given the proper tools. They do it all the time, it’s amazing!<br> 
        <br>There’s a place for everyone at the table and I’m grateful for modern medicine.<br>
        <br>Indeed, the strides we’ve made are astonishing, providing us with invaluable life-saving and life-enhancing treatments that were unimaginable just a few generations ago. After all, it’s a gift not to feel our surgeries.<br>
        <br>But longevity and health happens when we go back to our roots. <br>
        <br>Sun. Soil. Real Food. Play.<br>
        <br>Naturopathy and other Integrative health practices are gaining recognition for their full-body approach and profound results.<br>
        <br>It’s my joy to be able to use all that God gives us to help people heal. <br>
        <br>My desire is for people to live the way they were intended to: in good health.<br>
        <br>I believe in healing, not just medicating.<br>
        <br>Thriving, not surviving.
      </p>
      </div>
    </div>
    <div class="section-2">
      <div class="content-container">
        <h2></h2>
        <div class="grid-container">
          <div class="grid-item">
            <h3>FUNCTIONAL LAB TESTING</h3>
            <ul class="lab-test-links">
              <li><a href="https://equi.life/products/food-sensitivity-test" target="_blank">FOOD SENSITIVITY</a></li>
              <li><a href="https://equi.life/products/organic-acids-test" target="_blank">CANDIDA, METABOLIC, VITAMINS</a></li>
              <li><a href="https://equi.life/products/thyroid-adrenal-hormones-test" target="_blank">COMPLETE STRESS, MOOD & METABOLISM TEST</a></li>
              <li><a href="https://equi.life/products/hair-tissue-mineral-analysis-test" target="_blank">MINERALS & METALS</a></li>
              <li><a href="https://equi.life/products/stool-test" target="_blank">GUT BACTERIA & PARASITE</a></li>
            </ul>
          </div>
          <div class="grid-item">
            <h3>RESTORE YOUR</h3>
            <ul>
              <li>DIGESTION</li>
              <li>HORMONAL BALANCE</li>
              <li>NERVOUS SYSTEM REGULATION</li>
              <li>EMOTIONAL HEALTH</li>
              <li>STRESS RESILIENCE</li>
              <li>SLEEP</li>
            </ul>
          </div>
        </div>
        <p class="offer">I OFFER MY CLIENTS 15% OFF ON SELECT HEALTH TESTS.</p>
        <p class="additional-info">I AM ALSO HAPPY TO MAKE BLOODWORK RECOMMENDATIONS FOR YOUR PCP, AND GUIDE YOU TO PLACES YOU CAN REQUEST LABS FOR YOURSELF.</p>
      </div>
      <div class="content-container">
        <h2 style="font-family: 'Poppins Regular'; margin-top: 150px; font-size: 50px;">What You Can Expect</h2>
        <div class="grid-container2">
          <div class="grid-item">
            <h3>Thoughtful Questions</h3>
            <p>As an Integrative Health Practitioner, I work to uncover the root of your symptoms and help bring your body back into balance and you feeling your best and true self. In my own journey, I have worked with multiple practitioners and find that asking questions is a crucial part of the process. And there were many questions I was not asked or topics that were not brought up that would have aided me in my healing journey. With that being said, I'm grateful for all of the practitioners I have worked with and to be have been able to see the gaps, that I can now close in my own practice.</p>


            <h3>Lab Recommendations</h3>
            <p>Lab testing is a great way to look deeper within the body to see what's going on. Symptoms can arise from gut imbalances, vitamin deficiencies, mineral depletion, chronic stress, you name it. Testing can show you all of these! If testing is out of your budget, we will still work on identifying the roots and creating a plan to support the whole body. If you choose to do testing, I am trained in various functional lab tasting as well as select blood panels, where I use functional ranges.</p>

            <h3>Lifestyle & Diet Recommendations</h3>
            <p>Depending on your goals (losing weight, gaining weight etc.) I will help you create a general meal plan so that you hit all of your important macros and your body feels safe & well nourished. Lifestyle changes are equally important & make a large impact in the long run, so I will be providing recommendations on how to best set up your environment & body for success.</p>

            <h3>Supplement Recommendations</h3>
            <p>Supplements are a vital part in helping the body heal because most people are vitamin/mineral depleted, especially after chronic stress. We use supplements to help the body regain energy, correct imbalances and clear out toxicities, and then wean over time.</p>

            <h3>Coaching</h3>
            <p>You are not alone! It's easy for health to feel overwhelming, but that's what I'm here for. I will provide you with a personalized plan, tailored to your goals and needs — and answer any questions/concerns along the way. I will also be there to help you with your mindset as you take the steps. After all, health is not just physical. In my personal journey — I started to see much more results when I began to unpack my emotions, beliefs etc. and understood the role of my mind in it all. You don't have to do the unpacking with me by any means, but my goal is to bring awareness as this is a huge key for nervous system regulation, which is essential for healing and in turn well being.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'App',
  mounted() {
    this.setupScrollEffect();
    this.setupMouseMoveEffect();

  },
  methods: {
    setupScrollEffect() {
      const textContainers = gsap.utils.toArray('.text-effect');

      textContainers.forEach(container => {
        const letters = container.innerText.split('');
        container.innerHTML = letters.map(letter => `<span>${letter}</span>`).join('');

        gsap.fromTo(container.children, {
          color: '#848676'
        }, {
          color: '#d2d2d2',
          duration: 1,
          stagger: 0.05,
          ease: 'power2.inOut', // Add ease-in-out effect
          scrollTrigger: {
            trigger: container,
            start: 'top 80%',
            end: 'bottom 50%',
            scrub: 1 // Adjust the scrub value for smoother behavior
          }
        });
      });
    },
    setupMouseMoveEffect() {
      const nameElement = document.querySelector('.name');
      const textOverlay = document.querySelector('.text-overlay');

      textOverlay.addEventListener('mousemove', (event) => {
        const { clientX, clientY } = event;
        const { offsetWidth, offsetHeight } = textOverlay;
        const centerX = offsetWidth / 2;
        const centerY = offsetHeight / 2;
        const moveX = (clientX - centerX) / centerX;
        const moveY = (clientY - centerY) / centerY;

        nameElement.style.transform = `translate(${moveX * 3}px, ${moveY * 3}px)`;
      });

      textOverlay.addEventListener('mouseleave', () => {
        nameElement.style.transform = 'translate(0, 0)';
      });
    },
  }
}
</script>

<style scoped>


.lab-test-links li:hover a {
  color: #8f9784;
  text-decoration: underline;
}

.grid-item li, h3, p {
  font-family: 'Poppins Regular';
}
</style>