<template>
    <div class="terms-page">
        <nav class="nav-secondary">
            <div class="wrap">
                <ul class="genesis-nav-menu">
                <li class="menu-item">
                    <router-link to="/" exact-active-class="active"><span>Home</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/about#about-page" exact-active-class="active"><span>Marianna</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/ihp#ihp-page" exact-active-class="active"><span>ihp</span></router-link>
                </li>
                <!-- <li class="menu-item">
                    <router-link to="/shop#shop-page" exact-active-class="active"><span>Shop</span></router-link>
                </li> -->
                <li class="menu-item">
                    <router-link to="/blog#blog-page" exact-active-class="active"><span>Blog</span></router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/contact#contact-page" exact-active-class="active"><span>Contact</span></router-link>
                </li>
                </ul>
            </div>
        </nav>
        <div class="terms-of-service">
            <h1>Full Disclaimer</h1>
    
                <h2>Introduction</h2>
                <p>Welcome to Marianna Ionashku’s website. The information provided on this website is for general informational purposes only. The information provided is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. The information contained on this website is not intended to diagnose, treat or cure disease and does not constitute medical advice.</p>
                <p>The content provided on this website, such as text, graphics, images, and other material, is for informational purposes only. It is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</p>
                
                <h2>Responsibility for Health Decisions</h2>
                <p>By reading this website, you acknowledge that you are responsible for your own health decisions. Any statements or claims about the possible health benefits conferred by any foods or supplements have not been evaluated by the Food & Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease.</p>
                
                <h2>Standard Disclaimer</h2>
                <p>All information and resources found on mariannaionashku.com are based on the opinions of the author and are meant to motivate readers to make their own nutrition and health decisions after consulting with their health care provider. Readers should consult their doctor or health practitioner before making any health changes, especially any changes related to a specific diagnosis or condition. No information on this site should be relied upon to determine diet, make a medical diagnosis, or determine a treatment for a medical condition. The information on this website is not intended to replace a one-on-one relationship with a qualified health care professional and is not intended as medical advice. No information on this site should be used to diagnose, treat, prevent, or cure any disease or condition.</p>
                
                <h2>No Endorsement</h2>
                <p>The mention of specific products or services on this website does not constitute or imply a recommendation or endorsement by Marianna Ionashku, unless explicitly stated. The views and opinions expressed on this website are solely those of the original authors and other contributors.</p>
                
                <h2>Use of Content</h2>
                <p>None of the posts and articles on mariannaionashku.com may be reprinted without express written permission of the author and of Marianna Ionashku. Excerpts/quotes (10% or less) may be reprinted as long as all links are left intact and mariannaionashku.com and its content page are linked to with a live hyperlink.</p>
                
                <h2>Affiliate Links</h2>
                <p>While many of the links on mariannaionashku.com are purely for informational purposes, Marianna Ionashku may earn a small commission on many such links, including links to books, products, and services. Marianna Ionashku does not write paid posts. The thoughts and opinions expressed within Marianna Ionashku’s website are their own and not those of any sponsor or advertiser. Please view affiliate disclosure for additional details.</p>
                
                <h2>FTC Compliance</h2>
                <p>For the purposes of complying with FTC rules, please assume that, for every recommendation, link, and product that Marianna Ionashku uses, the following all hold true: Marianna Ionashku has been given a gadget, food, software, technology, clothes, equipment, or any other product that we thought may be of interest to my readers and clients to test and write about. And after Marianna Ionashku does our own research and decides this item is of value, we choose to promote that item and we may get compensated for it. Marianna Ionashku only mentions items that we believe in and feel would be a benefit to you.</p>
                
                <h2>Privacy</h2>
                <p>Marianna Ionashku recognizes that personal decisions about creating powerful health are among the most important private decisions people can make. It is our purpose to protect the privacy that the community of people seeking natural healing expects. By entering this part of the website, you are seeking and communicating about private issues regarding your health.</p>
                
                <h2>Health Suggestions</h2>
                <p>The health suggestions and opinions expressed by Marianna Ionashku on this website are based on the education and research of the owner. The knowledge and experience are not necessarily shared, nor have they been evaluated or approved by the F.D.A., the A.M.A., or any other federal, state, local or private agency. With regard to any dietary substances discussed herein, we are required to state in accordance with DSHEA, the Dietary Health and Education Act of 1994, “These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.”</p>
                
                <h2>Therapies and Products</h2>
                <p>Marianna Ionashku discusses therapies and products that may have a benefit which is not offered to diagnose or prescribe for medical or psychological conditions nor to claim to prevent, treat, mitigate, or cure such conditions, nor to make recommendations for treatment of disease or to provide diagnosis, care, treatment, or rehabilitation of individuals, or apply medical, mental health or human development principles.</p>
                
                <h2>Medical Advice</h2>
                <p>Therefore, if you are ill, have any disease, are pregnant, or just improving your health, we are required to warn you to go to a medical doctor for medical advice, treatment, and services.</p>
                
                <h2>Agreement</h2>
                <p>Upon entering and/or purchasing from this site, you hereby agree to take full responsibility for yourself, your health and release, indemnify and hold harmless, Marianna Ionashku. You are entering a community for natural health & healing and seeking information and products based on those principles thereby granting a private license to Marianna Ionashku to provide you the information herein.</p>
                
                <h2>Scope of Practice</h2>
                <p>As a participant in the Integrative Health Practitioner Institute courses, the implied understanding is that all content up to and including video lectures, informational slides, podcasts, workshops, handouts, case studies, labs, supplementation, and recommended reading material is provided for health education purposes only.</p>
                <p>The intention of health education in the scope of practice as a participant of Integrative Health Practitioner Institute courses is to provide supplement and lab education only and should not be used to prescribe, diagnose, treat, cure, or prevent any physiological or mental disease or disorder.</p>
                
                <h2>Copyright</h2>
                <p>All written material and images are copyrighted.</p>
                
                <h2>Agreement to Terms</h2>
            <p>If you agree with all of the above, and if you are acting as a private person without subterfuge or as a public agent, you may enter and/or purchase from this website.</p>
        </div>
    </div>  
</template>

<script>
  export default {
    name: 'FullDisclaimer',
  }
</script>


<style scoped>

.terms-page {
    background-color: #8c8d7b;
    width: 100vw;
}
  .terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    font-family: 'Poppins Regular';
  }

  h2 {
    font-size: 1.5rem;
    color: #474e3d;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  h3 {
    font-size: 1rem;
  }

  .terms-links {
    color: blue;
  }
</style>