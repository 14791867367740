/* eslint-disable */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5TeDCAB2Em8TJRjiOYxBZ-LSTYn2r5hk",
  authDomain: "marianna-a54c1.firebaseapp.com",
  projectId: "marianna-a54c1",
  storageBucket: "marianna-a54c1.appspot.com",
  messagingSenderId: "179851596931",
  appId: "1:179851596931:web:c67108fe2f764da5d777b6",
  measurementId: "G-CCJ601J143"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);