import { createApp } from 'vue';
import App from './App.vue';
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire';
import { db } from './firebase';
import './assets/global.css';
import router from './router';


const app = createApp(App);
app.use(VueFire, {
  modules: [VueFireFirestoreOptionsAPI()],
  firestore: db,
});
app.use(router);
app.mount('#app');